@import "@yaireo/tagify/dist/tagify.css";


.tagify {
  padding: 5px 5px;
  --tag-hide-transition: 0s;
}

.tagify--mix {
  border-radius: 4px;
}

.tagify__input {
  font-size: 12px;
  overflow: auto;
  min-height: 20px;
}

.tagify__tag {
  margin-top: 2px;
}

.tagify__tag__removeBtn {
  display: none;
}

.tagify__tag[type='DYNAMIC_FIELD'] {
  --tag-bg: #E9E8EE;
  --tag-text-color: #212121DC;
}

.tagify__tag[type='PLACEHOLDER'] {
  --tag-bg: #E1EDFD;
  --tag-text-color: #4487F3;
  --tag-remove-btn-color: #4487F3;
}

.htmlDynamicField[disabled] {
  background: none;
  filter: none;
  opacity: 1;
}

/* prevents text wrap around the tag */
.htmlDynamicField.previewMode table td {
  white-space: nowrap;
}

/* tag container */
.htmlDynamicField.previewMode .tagify__tag {
  display: inline-block;
  font-size: 12px;
  max-width: 100px;
  overflow: hidden;
}

/* tag text */
.htmlDynamicField.previewMode .tagify__tag span{
  display: inline-block;
  width: 100%;
  text-overflow: ellipsis;
}
