@font-face {
  font-family: 'inventive';
  src:  url('fonts/inventive.eot?zb25mk');
  src:  url('fonts/inventive.eot?zb25mk#iefix') format('embedded-opentype'),
    url('fonts/inventive.ttf?zb25mk') format('truetype'),
    url('fonts/inventive.woff?zb25mk') format('woff'),
    url('fonts/inventive.svg?zb25mk#inventive') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'inventive' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-add:before {
  content: "\e900";
}
.icon-add-circle:before {
  content: "\e901";
}
.icon-arrow-circle-up:before {
  content: "\e902";
}
.icon-arrow-diagonal-right:before {
  content: "\e903";
}
.icon-arrow-down:before {
  content: "\e904";
}
.icon-arrow-expand:before {
  content: "\e905";
}
.icon-arrow-left:before {
  content: "\e906";
}
.icon-arrow-right:before {
  content: "\e907";
}
.icon-arrow-round-left:before {
  content: "\e908";
}
.icon-arrow-round-right:before {
  content: "\e909";
}
.icon-arrow-up:before {
  content: "\e90a";
}
.icon-at:before {
  content: "\e90b";
}
.icon-bell-active:before {
  content: "\e90c";
}
.icon-bell-add:before {
  content: "\e90d";
}
.icon-bell-bolt:before {
  content: "\e90e";
}
.icon-bell-off:before {
  content: "\e90f";
}
.icon-bell-on:before {
  content: "\e910";
}
.icon-bell-settings:before {
  content: "\e911";
}
.icon-block:before {
  content: "\e912";
}
.icon-bolt:before {
  content: "\e913";
}
.icon-bookmark:before {
  content: "\e914";
}
.icon-bookmark-filled:before {
  content: "\e915";
}
.icon-bookmark-multi:before {
  content: "\e916";
}
.icon-bracket:before {
  content: "\e917";
}
.icon-branch:before {
  content: "\e918";
}
.icon-browser-code:before {
  content: "\e919";
}
.icon-browser-key:before {
  content: "\e91a";
}
.icon-building:before {
  content: "\e91b";
}
.icon-calculator:before {
  content: "\e91c";
}
.icon-calendar:before {
  content: "\e91d";
}
.icon-calendar-bolt:before {
  content: "\e91e";
}
.icon-carat-down:before {
  content: "\e91f";
}
.icon-carat-left:before {
  content: "\e920";
}
.icon-carat-right:before {
  content: "\e921";
}
.icon-carat-up:before {
  content: "\e922";
}
.icon-chart-bar:before {
  content: "\e923";
}
.icon-chart-line:before {
  content: "\e924";
}
.icon-chart-pie:before {
  content: "\e925";
}
.icon-chat:before {
  content: "\e926";
}
.icon-chat-double:before {
  content: "\e927";
}
.icon-chat-logs:before {
  content: "\e928";
}
.icon-chat-new:before {
  content: "\e929";
}
.icon-chat-settings:before {
  content: "\e92a";
}
.icon-check:before {
  content: "\e92b";
}
.icon-check-circle:before {
  content: "\e92c";
}
.icon-close:before {
  content: "\e92d";
}
.icon-close-circle:before {
  content: "\e92e";
}
.icon-collapse:before {
  content: "\e92f";
}
.icon-columns:before {
  content: "\e930";
}
.icon-connection:before {
  content: "\e931";
}
.icon-copy:before {
  content: "\e932";
}
.icon-dashboard:before {
  content: "\e933";
}
.icon-data:before {
  content: "\e934";
}
.icon-data-connect:before {
  content: "\e935";
}
.icon-data-edit:before {
  content: "\e936";
}
.icon-data-exclamation:before {
  content: "\e937";
}
.icon-data-schema:before {
  content: "\e938";
}
.icon-data-search:before {
  content: "\e939";
}
.icon-data-setting:before {
  content: "\e93a";
}
.icon-download:before {
  content: "\e93b";
}
.icon-error:before {
  content: "\e93c";
}
.icon-filter:before {
  content: "\e93d";
}
.icon-flare:before {
  content: "\e93e";
}
.icon-hamburger:before {
  content: "\e93f";
}
.icon-heart-spark:before {
  content: "\e940";
}
.icon-help:before {
  content: "\e941";
}
.icon-hide:before {
  content: "\e942";
}
.icon-history:before {
  content: "\e943";
}
.icon-home:before {
  content: "\e944";
}
.icon-image:before {
  content: "\e945";
}
.icon-indeterminate:before {
  content: "\e946";
}
.icon-info:before {
  content: "\e947";
}
.icon-light:before {
  content: "\e948";
}
.icon-line-decrease:before {
  content: "\e94a";
}
.icon-link:before {
  content: "\e94c";
}
.icon-list:before {
  content: "\e94d";
}
.icon-line-increase:before {
  content: "\e94b";
}
.icon-lock:before {
  content: "\e94e";
}
.icon-mail:before {
  content: "\e94f";
}
.icon-menu:before {
  content: "\e950";
}
.icon-moon:before {
  content: "\e985";
}
.icon-more-horizontal:before {
  content: "\e951";
}
.icon-music:before {
  content: "\e949";
}
.icon-new-tab:before {
  content: "\e952";
}
.icon-notification:before {
  content: "\e953";
}
.icon-number:before {
  content: "\e954";
}
.icon-packages:before {
  content: "\e955";
}
.icon-palette:before {
  content: "\e956";
}
.icon-pause:before {
  content: "\e957";
}
.icon-pencil:before {
  content: "\e958";
}
.icon-play:before {
  content: "\e959";
}
.icon-puzzle:before {
  content: "\e95a";
}
.icon-repeat:before {
  content: "\e95b";
}
.icon-return:before {
  content: "\e95c";
}
.icon-search:before {
  content: "\e95d";
}
.icon-send:before {
  content: "\e95e";
}
.icon-settings:before {
  content: "\e95f";
}
.icon-settings-automation:before {
  content: "\e960";
}
.icon-shapes:before {
  content: "\e961";
}
.icon-share:before {
  content: "\e962";
}
.icon-show:before {
  content: "\e963";
}
.icon-slack:before {
  content: "\e964";
}
.icon-sort:before {
  content: "\e965";
}
.icon-sort-down:before {
  content: "\e966";
}
.icon-sort-up:before {
  content: "\e967";
}
.icon-sparkle:before {
  content: "\e968";
}
.icon-stack:before {
  content: "\e969";
}
.icon-stop:before {
  content: "\e96a";
}
.icon-sun:before {
  content: "\e986";
}
.icon-table:before {
  content: "\e96b";
}
.icon-table-data:before {
  content: "\e96c";
}
.icon-test:before {
  content: "\e96d";
}
.icon-text:before {
  content: "\e96e";
}
.icon-text-bold:before {
  content: "\e96f";
}
.icon-text-italic:before {
  content: "\e970";
}
.icon-text-remove:before {
  content: "\e971";
}
.icon-thumbs-down:before {
  content: "\e972";
}
.icon-thumbs-down-filled:before {
  content: "\e973";
}
.icon-thumbs-up:before {
  content: "\e974";
}
.icon-thumbs-up-filled:before {
  content: "\e975";
}
.icon-time:before {
  content: "\e976";
}
.icon-tools:before {
  content: "\e977";
}
.icon-trash:before {
  content: "\e978";
}
.icon-upload:before {
  content: "\e979";
}
.icon-user:before {
  content: "\e97a";
}
.icon-user-create:before {
  content: "\e97b";
}
.icon-user-multiple:before {
  content: "\e97c";
}
.icon-warning:before {
  content: "\e97d";
}
.icon-workspace-create:before {
  content: "\e97e";
}
.icon-workspace-settings:before {
  content: "\e97f";
}
.icon-workspaces:before {
  content: "\e980";
}
.icon-world:before {
  content: "\e981";
}
.icon-wrench:before {
  content: "\e982";
}
.icon-writing:before {
  content: "\e983";
}
.icon-zoom-code:before {
  content: "\e984";
}
