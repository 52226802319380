@keyframes dotAnimation {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.loadingDots {
  display: inline-block;
  font-weight: bolder;
  margin-left: 0.125rem;
}

.dot {
  animation-name: dotAnimation;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
}

.dot1 {
  animation-delay: 0s;
  margin-left: 0.125rem;
}

.dot2 {
  animation-delay: 0.33s;
  margin-left: 0.125rem;
}

.dot3 {
  animation-delay: 0.66s;
  margin-left: 0.125rem;
}
